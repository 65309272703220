// src/components/ReferralList.js
import React, { useState, useEffect } from "react";
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    useMediaQuery,
    Box,
    Button,
    Flex,
    Text,
    Avatar,
    Tag,
    Badge,
} from "@chakra-ui/react";
import { formatDateTime } from "../utils/formatDT";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { useAmbassador } from "./../context/AmbassadorContext";
import { Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import { GrMore } from "react-icons/gr";
import { MdDeleteOutline } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import { useEnv } from "../context/EnvContext";

const ReferralList = () => {
    const { backendUrl } = useEnv();
    const { userData } = useAuth();
    const navigate = useNavigate();
    const [isMobile] = useMediaQuery("(max-width: 768px)");
    const { candidates, deleteCandidate } = useAmbassador();

    console.log("candidates", candidates);
    return (
        <div className="mt-4">
            <h2 className="text-lg font-bold mb-2 mx-2">My Referrals</h2>
            <div className="border p-4 rounded-md">
                {candidates.length === 0 ? (
                    <p>No Referalls found.</p>
                ) : isMobile ? (
                    <Box>
                        {candidates.map((referral, index) => (
                            <Box
                                key={index}
                                py={2}
                                style={{ position: "relative" }}
                            >
                                <Button
                                    onClick={() => {
                                        const data = {
                                            operation: "update",
                                            candidate: referral,
                                        };
                                        navigate("/ambassador", {
                                            state: { data },
                                        });
                                    }}
                                    style={{
                                        position: "absolute",
                                        right: "2px",
                                        top: "2px",
                                    }}
                                >
                                    update
                                </Button>
                                <div>
                                    <strong>Referral Count:</strong>{" "}
                                    {candidates.length - index}
                                </div>
                                <div>
                                    <strong>Name:</strong>{" "}
                                    {referral.candidateName}
                                </div>
                                <div>
                                    <strong>Email:</strong>{" "}
                                    {referral.candidateEmail}
                                </div>
                                <div>
                                    <strong>College:</strong>{" "}
                                    {referral.collegeName == "Other"
                                        ? `${referral.otherCollegeName}`
                                        : `${referral.collegeName}`}
                                </div>
                                <div>
                                    <strong>Contact:</strong>{" "}
                                    {referral.contactNumber}
                                </div>
                                <div>
                                    <strong>Process:</strong>{" "}
                                    {referral.regProces}
                                </div>
                                {referral.regProces == "Manually" && (
                                    <div>
                                        <strong>Transaction Id:</strong>{" "}
                                        {referral.transactionId}
                                    </div>
                                )}
                                <div>
                                    <strong>Created At:</strong>{" "}
                                    {formatDateTime(referral.createdAt).date}{" "}
                                    {formatDateTime(referral.createdAt).time}
                                </div>
                                <div>
                                    <strong>Validity Status:</strong>{" "}
                                    {referral.validityStatus == "Valid" && (
                                        <Badge colorScheme="green">Valid</Badge>
                                    )}
                                    {referral.validityStatus == "Invalid" && (
                                        <Badge colorScheme="red">Invalid</Badge>
                                    )}
                                    {referral.validityStatus == "Pending" && (
                                        <Badge colorScheme="orange">
                                            Pending
                                        </Badge>
                                    )}
                                </div>
                                <hr style={{ marginTop: "15px" }} />
                            </Box>
                        ))}
                    </Box>
                ) : (
                    <TableContainer>
                        <Table size="sm">
                            <Thead>
                                <Tr>
                                    <Th>Ref.</Th>
                                    <Th>Name</Th>
                                    <Th>Email</Th>
                                    <Th>College</Th>
                                    <Th>Contact</Th>
                                    <Th>Course</Th>
                                    <Th>Process</Th>
                                    <Th>Validity Status</Th>
                                    <Th>Added On</Th>
                                    {userData?.user?.role == "Admin" && (
                                        <Td>Referred By</Td>
                                    )}
                                    <Th></Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {candidates.map((referral, index) => (
                                    <Tr key={index}>
                                        <Td> {candidates.length - index}</Td>
                                        <Td>{referral.candidateName}</Td>
                                        <Td>{referral.candidateEmail}</Td>
                                        <Td>
                                            {referral.collegeName == "Other"
                                                ? `${referral.otherCollegeName}`
                                                : `${referral.collegeName}`}
                                        </Td>
                                        <Td>{referral.contactNumber}</Td>
                                        <Td>{referral.courseRegistered}</Td>
                                        <Td>
                                            {referral.regProces}{" "}
                                            {referral.regProces == "Manually" &&
                                                referral.transactionId}
                                        </Td>
                                        <Td>
                                            {referral.validityStatus ==
                                                "Valid" && (
                                                <Badge colorScheme="green">
                                                    Valid
                                                </Badge>
                                            )}
                                            {referral.validityStatus ==
                                                "Invalid" && (
                                                <Badge colorScheme="red">
                                                    Invalid
                                                </Badge>
                                            )}
                                            {referral.validityStatus ==
                                                "Pending" && (
                                                <Badge colorScheme="orange">
                                                    Pending
                                                </Badge>
                                            )}
                                        </Td>
                                        <Td>
                                            {
                                                formatDateTime(
                                                    referral.createdAt
                                                ).date
                                            }{" "}
                                            {
                                                formatDateTime(
                                                    referral.createdAt
                                                ).time
                                            }
                                        </Td>
                                        {userData?.user?.role == "Admin" && (
                                            <Td>
                                                <Flex gap={2}>
                                                    <Avatar
                                                        size="md"
                                                        name={
                                                            referral.referredBy
                                                                ?.name
                                                        }
                                                        src={`${backendUrl}${referral.referredBy?.image}`}
                                                    />
                                                    <Flex
                                                        direction={"column"}
                                                        gap={1}
                                                        justify={"center"}
                                                    >
                                                        <Text
                                                            fontSize={"md"}
                                                            fontWeight={"bold"}
                                                        >
                                                            {
                                                                referral
                                                                    .referredBy
                                                                    .name
                                                            }
                                                        </Text>
                                                        <Text>
                                                            {
                                                                referral
                                                                    .referredBy
                                                                    .email
                                                            }
                                                        </Text>
                                                    </Flex>
                                                </Flex>
                                            </Td>
                                        )}
                                        <Td>
                                            <Menu>
                                                <MenuButton>
                                                    <GrMore />
                                                </MenuButton>
                                                <MenuList>
                                                    <MenuItem
                                                        icon={<FaEdit />}
                                                        onClick={() => {
                                                            const data = {
                                                                operation:
                                                                    "update",
                                                                candidate:
                                                                    referral,
                                                            };
                                                            navigate(
                                                                "/ambassador",
                                                                {
                                                                    state: {
                                                                        data,
                                                                    },
                                                                }
                                                            );
                                                        }}
                                                    >
                                                        Update Candidate
                                                    </MenuItem>
                                                    <MenuItem
                                                        icon={
                                                            <MdDeleteOutline />
                                                        }
                                                        onClick={() => {
                                                            deleteCandidate(
                                                                referral._id
                                                            );
                                                        }}
                                                        style={{ color: "red" }}
                                                    >
                                                        Delete Candidate
                                                    </MenuItem>
                                                </MenuList>
                                            </Menu>
                                        </Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </TableContainer>
                )}
            </div>
        </div>
    );
};

export default ReferralList;
