// src/LoginForm.js
import React, { useState } from "react";
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Input,
    Text,
    InputGroup,
    InputRightElement,
    IconButton,
    Checkbox,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { useAuth } from "../context/AuthContext";
import HomeNavbar from "./HomeNavbar";

const LoginForm = () => {
    const { login } = useAuth();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [rememberMe, setRememberMe] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        login(email, password, rememberMe);
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    return (
        <div
            className="flex flex-col items-center min-h-screen bg-gradient-to-b from-gray-900 to-green"
            style={{ height: "100vh" }}
        >
            <HomeNavbar task={"Apply Job"} />
            <Text className=" mb-2 text-white font-bold text-2xl mt-10">
                Login Form
            </Text>
            <Box
                bg="white"
                p={6}
                rounded="md"
                width="100%"
                maxWidth="md"
                boxShadow="xl"
            >
                <form onSubmit={handleSubmit}>
                    <FormControl id="email" isRequired>
                        <FormLabel>Email</FormLabel>
                        <Input
                            type="email"
                            placeholder="Enter your email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </FormControl>
                    <FormControl id="password" mt={4} isRequired>
                        <FormLabel>Password</FormLabel>
                        <InputGroup>
                            <Input
                                type={showPassword ? "text" : "password"}
                                placeholder="Enter your password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <InputRightElement>
                                <IconButton
                                    variant="ghost"
                                    aria-label={
                                        showPassword
                                            ? "Hide password"
                                            : "Show password"
                                    }
                                    icon={
                                        showPassword ? (
                                            <ViewOffIcon />
                                        ) : (
                                            <ViewIcon />
                                        )
                                    }
                                    onClick={togglePasswordVisibility}
                                />
                            </InputRightElement>
                        </InputGroup>
                    </FormControl>
                    <FormControl display="flex" alignItems="center" mt={4}>
                        <Checkbox
                            id="rememberMe"
                            isChecked={rememberMe}
                            onChange={(e) => setRememberMe(e.target.checked)}
                        >
                            Remember Me
                        </Checkbox>
                    </FormControl>
                    <Button
                        colorScheme="teal"
                        width="full"
                        mt={4}
                        type="submit"
                    >
                        Login
                    </Button>
                </form>
            </Box>
        </div>
    );
};

export default LoginForm;
