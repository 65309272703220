import { ViewIcon } from "@chakra-ui/icons";
import {
    Avatar,
    Button,
    Flex,
    Heading,
    IconButton,
    Image,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { DisplayProfilePicModal } from "./DisplayProfilePicModal";
import { useEnv } from "../context/EnvContext";

export const ProfileModal = ({ selectedUser, children }) => {
    const { backendUrl } = useEnv();
    const { isOpen, onOpen, onClose } = useDisclosure();

    // console.log("selectedUser", selectedUser);
    return (
        <>
            {children ? (
                <span onClick={onOpen}>{children}</span>
            ) : (
                <></>
                // <IconButton display={{ base: "flex" }} icon={<ViewIcon />} onClick={onOpen} />
            )}

            <Modal size={"lg"} isOpen={isOpen} onClose={onClose} isCentered>
                <ModalOverlay />
                <ModalContent h={"auto"}>
                    <ModalCloseButton />
                    <ModalBody>
                        <Flex gap={5}>
                            <DisplayProfilePicModal selectedUser={selectedUser}>
                                <Avatar
                                    size="2xl"
                                    name={selectedUser?.name}
                                    src={`${backendUrl}${selectedUser?.image}`}
                                    cursor={"pointer"}
                                />
                            </DisplayProfilePicModal>
                            <Flex direction={"column"}>
                                <Heading as="h2" size="xl">
                                    {selectedUser?.name}
                                </Heading>
                                <Text fontSize="xl" fontWeight={"bold"}>
                                    Id: {selectedUser?.baId}
                                </Text>
                                <Text fontSize="17px">
                                    <b>Email:</b> {selectedUser?.email}
                                </Text>
                                <Text fontSize={"17px"}>
                                    <b>Phone:</b> {selectedUser?.contactNumber}
                                </Text>
                            </Flex>
                        </Flex>
                        <hr style={{ margin: "10px 0px" }} />

                        {selectedUser?.collegeName && (
                            <>
                                <Text fontSize={"17px"}>
                                    <b>College Name:</b>{" "}
                                    {selectedUser?.collegeName}
                                </Text>
                                {selectedUser?.branch && (
                                    <Text fontSize={"17px"}>
                                        <b>Branch:</b> {selectedUser?.branch}
                                    </Text>
                                )}
                                {selectedUser?.semester && (
                                    <Text fontSize={"17px"}>
                                        <b>Current Semester:</b>{" "}
                                        {selectedUser?.semester}
                                    </Text>
                                )}
                                {selectedUser?.passoutYear && (
                                    <Text fontSize={"17px"}>
                                        <b>Passout Year:</b>{" "}
                                        {selectedUser?.passoutYear}
                                    </Text>
                                )}
                            </>
                        )}
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};
