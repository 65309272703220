import axios from "axios";
import React, { createContext, useState, useContext, useEffect } from "react";
import toast from "react-hot-toast";
import { useEnv } from "./EnvContext";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
    const { backendUrl } = useEnv();
    const [user, setUser] = useState(null);
    const [userData, setUserData] = useState({});
    const key = "mona";
    console.log("user Data ###", userData);

    const navigate = useNavigate();

    const login = async (email, password, rememberMe) => {
        const loggedInUser = { email, password, rememberMe };
        setUser(loggedInUser);
        console.log("User logged in:", loggedInUser);
        let loadingToast;
        try {
            loadingToast = toast.loading("Checking your login credentials", {
                position: "top-right",
            });

            const response = await axios.post(`${backendUrl}/user/login`, {
                email,
                password,
            });
            toast.success("Login successful!", { position: "top-right" });
            console.log("response ", response);
            const data = response.data.data;
            // Encrypt user data
            const encryptedData = CryptoJS.AES.encrypt(
                JSON.stringify(data),
                key
            ).toString();

            // Store encrypted data in localStorage
            localStorage.setItem("userData", encryptedData);
            setUserData(data);

            const role = data.user.role;
            console.log("role", role);
            switch (role) {
                case "Admin":
                    navigate("/admin");
                    break;
                case "Ambassador":
                    navigate("/ambassador");
                    break;
                default:
                    toast.error("You are not Authorized");
                    break;
            }
        } catch (err) {
            console.log(err);
            if (err?.response?.data?.message) {
                toast.error(`${err.response.data.message}`, {
                    position: "top-right",
                });
            } else
                toast.error("Something went wrong", { position: "top-right" });
        } finally {
            if (loadingToast) toast.dismiss(loadingToast);
        }
    };

    const logout = () => {
        setUser(null);
        localStorage.removeItem("userData");
        console.log("User logged out");
    };

    return (
        <AuthContext.Provider
            value={{ user, login, logout, setUser, setUserData, userData }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};
