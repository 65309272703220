// src/components/AmbassadorNavbar.js
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuItemOption,
    MenuGroup,
    MenuOptionGroup,
    MenuDivider,
    Button,
    Avatar,
    Flex,
} from "@chakra-ui/react";
import { useAuth } from "../context/AuthContext";
import { useAmbassador } from "../context/AmbassadorContext";
import { useEnv } from "../context/EnvContext";
import { ProfileModal } from "./ProfileModal";

const AmbassadorNavbar = () => {
    const { backendUrl } = useEnv();
    const { candidates } = useAmbassador();
    const { logout, userData } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    const handleLogout = () => {
        logout();
        navigate("/");
    };

    console.log(
        "asdnasncaskcmikasjmciamsicm   ",
        `${backendUrl}${userData?.user?.image}`
    );
    return (
        <nav className="bg-blue-500 p-4 flex justify-between items-center">
            <button
                className="text-white text-lg font-bold"
                onClick={() => navigate("/ambassador")}
            >
                SOLPBA
            </button>

            <Flex gap={4}>
                {location.pathname == "/ambassador/referrals" && (
                    <button
                        style={{ position: "relative" }}
                        className="text-white mx-2"
                        onClick={() => navigate("/ambassador")}
                    >
                        Referral Form
                    </button>
                )}
                {location.pathname == "/ambassador" && (
                    <button
                        style={{ position: "relative" }}
                        className="text-white mx-2"
                        onClick={() => navigate("/ambassador/referrals")}
                    >
                        Referrals
                        <div
                            style={{
                                position: "absolute",
                                top: "-10px",
                                right: "-10px",
                                background: "red",
                                height: "15px",
                                minWidth: "15px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                fontSize: "10px",
                                fontWeight: "bold",
                                borderRadius: "8px",
                                paddingInline: "4px",
                            }}
                        >
                            {candidates?.length}
                        </div>
                    </button>
                )}
                <Menu>
                    <MenuButton>
                        <Avatar
                            size="sm"
                            name={userData?.user?.name}
                            src={`${backendUrl}${userData?.user?.image}`}
                        />
                    </MenuButton>
                    <MenuList maxW={"10px"}>
                        <MenuGroup title={`👋 ${userData?.user?.name}`}>
                            <MenuItem>
                                <ProfileModal selectedUser={userData.user}>
                                    My Profile
                                </ProfileModal>
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    const data = {
                                        operation: "update",
                                        candidate: userData.user,
                                    };
                                    navigate("/ambassador/update-profile", {
                                        state: {
                                            data,
                                        },
                                    });
                                }}
                            >
                                Update Profile
                            </MenuItem>
                            <MenuItem
                                onClick={() =>
                                    navigate("/ambassador/referrals")
                                }
                            >
                                My Referrals
                            </MenuItem>
                            <MenuItem onClick={() => navigate("/ambassador")}>
                                Referral Form
                            </MenuItem>
                            <MenuItem onClick={handleLogout}>Logout</MenuItem>
                        </MenuGroup>
                    </MenuList>
                </Menu>
            </Flex>
        </nav>
    );
};

export default AmbassadorNavbar;
