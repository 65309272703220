// src/components/ReferralForm.js
import { Button, Flex, Heading } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAmbassador } from "../context/AmbassadorContext";
import paymentQR from "../assets/payment_qr.jpg";

const ReferralForm = ({ candidate, operation }) => {
    const navigate = useNavigate();
    const { addCandidate, updateCandidate } = useAmbassador();
    const [formData, setFormData] = useState({
        candidateName: "",
        candidateEmail: "",
        collegeName: "",
        otherCollegeName: "",
        contactNumber: "",
        courseRegistered: "",
        regProces: "Through Website",
        transactionId: "",
    });

    useEffect(() => {
        if (operation == "Update Candidate") {
            setFormData({
                candidateName: candidate.candidateName,
                candidateEmail: candidate.candidateEmail,
                collegeName: candidate.collegeName,
                otherCollegeName: candidate.otherCollegeName,
                contactNumber: candidate.contactNumber,
                courseRegistered: candidate.courseRegistered,
                regProces: candidate.regProces,
                transactionId: candidate.transactionId,
            });
        }
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (formData.regProces === "Through Website") {
            formData.transactionId = "";
        }
        if (formData.collegeName !== "Other") {
            formData.otherCollegeName = "";
        }

        if (operation === "Add Candidate") {
            addCandidate(formData);
            handleClear();
        } else if (operation === "Update Candidate") {
            updateCandidate(formData, candidate._id);
            handleClear();
        }
    };

    const handleClear = () => {
        if (operation == "Update Candidate") {
            setFormData({
                candidateName: candidate.candidateName,
                candidateEmail: candidate.candidateEmail,
                collegeName: candidate.collegeName,
                otherCollegeName: candidate.otherCollegeName,
                contactNumber: candidate.contactNumber,
                courseRegistered: candidate.courseRegistered,
                regProces: candidate.regProces,
                transactionId: candidate.transactionId,
            });
        } else {
            setFormData({
                candidateName: "",
                candidateEmail: "",
                collegeName: "",
                otherCollegeName: "",
                contactNumber: "",
                courseRegistered: "",
                regProces: "Through Website",
                transactionId: "",
            });
        }
    };
    console.log(formData);

    return (
        <>
            <Flex justify={"space-between"} align={"center"} mb={2}>
                <Heading as="h4" size="md">
                    {operation}
                </Heading>
                {operation == "Update Candidate" && (
                    <Button
                        colorScheme="teal"
                        size="sm"
                        style={{ position: "relative" }}
                        onClick={() => navigate("/ambassador")}
                    >
                        Referral Form
                    </Button>
                )}
            </Flex>
            <hr />
            <form onSubmit={handleSubmit} className="p-4">
                <div className="mb-4">
                    <label className="block">Candidate Name</label>
                    <input
                        type="text"
                        name="candidateName"
                        value={formData.candidateName}
                        onChange={handleChange}
                        className="border p-2 w-full"
                    />
                </div>
                <div className="mb-4">
                    <label className="block">Candidate Email</label>
                    <input
                        type="email"
                        name="candidateEmail"
                        value={formData.candidateEmail}
                        onChange={handleChange}
                        className="border p-2 w-full"
                    />
                </div>
                <div className="mb-4">
                    <label className="block">Contact Number</label>
                    <input
                        type="text"
                        name="contactNumber"
                        value={formData.contactNumber}
                        onChange={handleChange}
                        className="border p-2 w-full"
                    />
                </div>
                <div className="mb-4">
                    <label className="block">College Name</label>
                    <select
                        name="collegeName"
                        value={formData.collegeName}
                        onChange={handleChange}
                        className="border p-2 w-full"
                    >
                        <option value="">Select College</option>
                        <option value="C.V. Raman Global University, Bhubaneswar">
                            C.V. Raman Global University, Bhubaneswar
                        </option>
                        <option value="College of Engineering and Technology (CET), Bhubaneswar">
                            Odisha University of Technology and Research (OUTR /
                            CET), Bhubaneswar
                        </option>
                        <option value="Eastern Academy of Science and Technology (EAST), Bhubaneswar">
                            Eastern Academy of Science and Technology (EAST),
                            Bhubaneswar
                        </option>
                        <option value="Gandhi Institute for Technological Advancement (GITA), Bhubaneswar">
                            Gandhi Institute for Technological Advancement
                            (GITA), Bhubaneswar
                        </option>
                        <option value="Indira Gandhi Institute of Technology (IGIT), Sarang">
                            Indira Gandhi Institute of Technology (IGIT), Sarang
                        </option>
                        <option value="Indian Institute of Technology (IIT), Bhubaneswar">
                            Indian Institute of Technology (IIT), Bhubaneswar
                        </option>
                        <option value="Institute of Technical Education and Research (ITER), Bhubaneswar">
                            Institute of Technical Education and Research
                            (ITER), Bhubaneswar
                        </option>
                        <option value="International Institute of Information Technology (IIIT), Bhubaneswar">
                            International Institute of Information Technology
                            (IIIT), Bhubaneswar
                        </option>
                        <option value="Kalinga Institute of Industrial Technology (KIIT), Bhubaneswar">
                            Kalinga Institute of Industrial Technology (KIIT),
                            Bhubaneswar
                        </option>
                        <option value="National Institute of Technology (NIT), Rourkela">
                            National Institute of Technology (NIT), Rourkela
                        </option>
                        <option value="Orissa Engineering College (OEC), Bhubaneswar">
                            Orissa Engineering College (OEC), Bhubaneswar
                        </option>
                        <option value="Parala Maharaja Engineering College (PMEC), Berhampur">
                            Parala Maharaja Engineering College (PMEC),
                            Berhampur
                        </option>
                        <option value="Silicon Institute of Technology, Bhubaneswar">
                            Silicon Institute of Technology, Bhubaneswar
                        </option>
                        <option value="Synergy Institute of Engineering and Technology, Dhenkanal">
                            Synergy Institute of Engineering and Technology,
                            Dhenkanal
                        </option>
                        <option value="Veer Surendra Sai University of Technology (VSSUT), Burla">
                            Veer Surendra Sai University of Technology (VSSUT),
                            Burla
                        </option>
                        <option value="Other">Other</option>
                    </select>
                    {formData.collegeName === "Other" && (
                        <input
                            type="text"
                            name="otherCollegeName"
                            value={formData.otherCollegeName}
                            onChange={handleChange}
                            className="border p-2 w-full mt-2"
                            placeholder="Enter College Name"
                        />
                    )}
                </div>
                <div className="mb-4">
                    <label className="block">Course Registered</label>
                    <select
                        name="courseRegistered"
                        value={formData.courseRegistered}
                        onChange={handleChange}
                        className="border p-2 w-full"
                    >
                        <option value="">Select Course</option>
                        <option value="C programming">C programming</option>
                        <option value="C++ programming">C++ programming</option>
                        <option value="C & C++ Combo for 1 year">
                            C & C++ Combo for 1 year
                        </option>
                        <option value="C & C++ Combo for 2 year">
                            C & C++ Combo for 2 year
                        </option>
                    </select>
                </div>
                <div className="mb-4">
                    <label className="block">Registration Process</label>
                    <select
                        name="regProces"
                        value={formData.regProces}
                        onChange={handleChange}
                        className="border p-2 w-full"
                    >
                        <option value="Through Website">Through Website</option>
                        <option value="Manually">Manually</option>
                    </select>
                </div>
                {formData.regProces == "Manually" && (
                    <>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <img
                                style={{ maxHeight: "300px" }}
                                src={paymentQR}
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block">Transaction Id</label>
                            <input
                                type="text"
                                name="transactionId"
                                value={formData.transactionId}
                                onChange={handleChange}
                                className="border p-2 w-full"
                            />
                        </div>
                    </>
                )}

                <button type="submit" className="bg-blue-500 text-white p-2">
                    Submit
                </button>
                <button
                    type="button"
                    onClick={handleClear}
                    className="bg-gray-500 text-white p-2 ml-2"
                >
                    Clear
                </button>
            </form>
        </>
    );
};

export default ReferralForm;
