// src/components/AdminNavbar.js
import React from "react";
import { useNavigate } from "react-router-dom";
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuItemOption,
    MenuGroup,
    MenuOptionGroup,
    MenuDivider,
    Button,
    Avatar,
    Flex,
} from "@chakra-ui/react";

const HomeNavbar = ({ task }) => {
    const navigate = useNavigate();

    const handleLogout = () => {
        // handle logout logic
        navigate("/");
    };

    return (
        <nav className="bg-teal-500 p-4 flex justify-between items-center w-full">
            <div className="text-white text-lg font-bold">SOLPBA</div>
            <button
                style={{ position: "relative" }}
                className="text-white mx-2"
                onClick={() => {
                    if (task == "Apply Job") {
                        navigate("/apply-job");
                    } else {
                        navigate("/");
                    }
                }}
            >
                {task}
            </button>
        </nav>
    );
};

export default HomeNavbar;
