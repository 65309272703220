import React, { useState, useEffect } from "react";
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Input,
    Text,
} from "@chakra-ui/react";
import { useAdmin } from "../context/AdminContext";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

const AddAmbassadorForm = ({ operation }) => {
    const { addUser, updateUser } = useAdmin();
    const location = useLocation();
    const { userData } = useAuth();
    const ambassador = userData.user;
    const navigate = useNavigate();
    console.log("ambassador t be updated", ambassador);

    const [formData, setFormData] = useState({
        name: "",
        email: "",
        collegeName: "",
        contactNumber: "",
        passoutYear: "",
        semester: "",
        branch: "",
        image: null,
        password: "",
    });

    useEffect(() => {
        if (operation === "Update Ambassador" && ambassador) {
            setFormData({
                name: ambassador.name || "",
                email: ambassador.email || "",
                collegeName: ambassador.collegeName || "",
                contactNumber: ambassador.contactNumber || "",
                passoutYear: ambassador.passoutYear || "",
                semester: ambassador.semester || "",
                branch: ambassador.branch || "",
                image: null,
                password: ambassador.password || "",
            });
        }
    }, [operation, ambassador]);

    console.log("formData", formData);

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        setFormData({
            ...formData,
            [name]: files ? files[0] : value,
        });
    };

    const handleSubmit = async (e) => {
        console.log("formData till now", formData);
        e.preventDefault();
        const data = new FormData();
        Object.keys(formData).forEach((key) => {
            if (formData[key]) {
                data.append(key, formData[key]);
            }
        });

        if (operation === "Add Ambassador") {
            await addUser(data);
        } else if (operation === "Update Ambassador") {
            await updateUser(data);
            navigate("/ambassador");
        }

        handleClear();
    };

    const handleClear = () => {
        setFormData({
            name: "",
            email: "",
            collegeName: "",
            contactNumber: "",
            passoutYear: "",
            semester: "",
            branch: "",
            image: null,
            password: "",
        });
    };

    return (
        <div
            className="flex flex-col items-center min-h-screen bg-gradient-to-b from-gray-900 to-green"
            style={{ height: "100vh" }}
        >
            <Text className=" mb-2 text-white font-bold text-2xl mt-10">
                {operation === "Add Ambassador"
                    ? "Apply For SOLP Ambassador"
                    : "Update Your Details"}
            </Text>
            <Box
                bg="white"
                p={6}
                rounded="md"
                width="100%"
                maxWidth="md"
                boxShadow="xl"
            >
                <form onSubmit={handleSubmit}>
                    <FormControl id="name" isRequired>
                        <FormLabel>Name</FormLabel>
                        <Input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                        />
                    </FormControl>
                    {operation === "Add Ambassador" && (
                        <FormControl id="email" isRequired>
                            <FormLabel>Email</FormLabel>
                            <Input
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                            />
                        </FormControl>
                    )}
                    <FormControl id="collegeName" isRequired>
                        <FormLabel>College Name</FormLabel>
                        <Input
                            type="text"
                            name="collegeName"
                            value={formData.collegeName}
                            onChange={handleChange}
                        />
                    </FormControl>
                    <FormControl id="contactNumber" isRequired>
                        <FormLabel>Contact Number</FormLabel>
                        <Input
                            type="text"
                            name="contactNumber"
                            value={formData.contactNumber}
                            onChange={handleChange}
                        />
                    </FormControl>
                    <FormControl id="passoutYear">
                        <FormLabel>Passout Year</FormLabel>
                        <Input
                            type="number"
                            name="passoutYear"
                            value={formData.passoutYear}
                            onChange={handleChange}
                            className="border p-2 w-full"
                        />
                    </FormControl>
                    <FormControl id="semester">
                        <FormLabel>Semester</FormLabel>
                        <Input
                            type="number"
                            name="semester"
                            value={formData.semester}
                            onChange={handleChange}
                        />
                    </FormControl>
                    <FormControl id="branch">
                        <FormLabel>Branch</FormLabel>
                        <Input
                            type="text"
                            name="branch"
                            value={formData.branch}
                            onChange={handleChange}
                        />
                    </FormControl>
                    {operation === "Update Ambassador" && (
                        <FormControl id="password">
                            <FormLabel>Password</FormLabel>
                            <Input
                                type="password"
                                name="password"
                                value={formData.password}
                                onChange={handleChange}
                            />
                        </FormControl>
                    )}
                    <FormControl id="file">
                        <FormLabel>Image</FormLabel>
                        <input
                            type="file"
                            name="image"
                            onChange={handleChange}
                            className="border p-2 w-full"
                            style={{ borderRadius: "0.375rem" }}
                        />
                    </FormControl>

                    <Flex gap="2">
                        <Button
                            colorScheme="red"
                            width="full"
                            mt={4}
                            onClick={handleClear}
                        >
                            Clear
                        </Button>
                        <Button
                            colorScheme="teal"
                            width="full"
                            mt={4}
                            type="submit"
                        >
                            Submit
                        </Button>
                    </Flex>
                </form>
            </Box>
        </div>
    );
};

export default AddAmbassadorForm;
