// src/components/AdminNavbar.js
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Avatar,
    Flex,
} from "@chakra-ui/react";
import { useAuth } from "../context/AuthContext";
import { useAdmin } from "../context/AdminContext";
import { useEnv } from "../context/EnvContext";
import { useAmbassador } from "../context/AmbassadorContext";

const AdminNavbar = () => {
    const { backendUrl } = useEnv();
    const navigate = useNavigate();
    const { user, logout } = useAuth();
    const { ambassadors, deletedUsers, jobSeekers } = useAdmin();
    const { candidates } = useAmbassador();
    const location = useLocation();
    console.log("user", user);

    console.log("ambassadors", ambassadors);
    console.log("deletedUsers", deletedUsers);
    console.log("jobSeekers", jobSeekers);
    console.log("user", user);

    const handleLogout = () => {
        logout();
        navigate("/");
    };

    return (
        <nav className="bg-blue-500 p-4 flex justify-between items-center">
            <button
                className="text-white text-lg font-bold"
                onClick={() => navigate("/admin")}
            >
                SOLPBA
            </button>
            <Flex gap={4}>
                <button
                    style={{ position: "relative" }}
                    className="text-white mx-2"
                    onClick={() => navigate("/admin/job-seekers")}
                >
                    Job Seekers
                    <div
                        style={{
                            position: "absolute",
                            top: "-10px",
                            right: "-10px",
                            background: "red",
                            height: "15px",
                            minWidth: "15px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "10px",
                            fontWeight: "bold",
                            borderRadius: "8px",
                            paddingInline: "4px",
                        }}
                    >
                        {jobSeekers?.length}
                    </div>
                </button>
                <button
                    style={{ position: "relative" }}
                    className="text-white mx-2"
                    onClick={() => navigate("/admin/ambassadors")}
                >
                    Ambassadors
                    <div
                        style={{
                            position: "absolute",
                            top: "-10px",
                            right: "-10px",
                            background: "red",
                            height: "15px",
                            minWidth: "15px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "10px",
                            fontWeight: "bold",
                            borderRadius: "8px",
                            paddingInline: "4px",
                        }}
                    >
                        {ambassadors?.length}
                    </div>
                </button>
                <button
                    style={{ position: "relative" }}
                    className="text-white mx-2"
                    onClick={() => navigate("/admin")}
                >
                    Referalls
                    <div
                        style={{
                            position: "absolute",
                            top: "-10px",
                            right: "-10px",
                            background: "red",
                            height: "15px",
                            minWidth: "15px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "10px",
                            fontWeight: "bold",
                            borderRadius: "8px",
                            paddingInline: "4px",
                        }}
                    >
                        {candidates?.length}
                    </div>
                </button>
                <Menu>
                    <MenuButton>
                        <Avatar
                            size="xs"
                            name="Kola Tioluwani"
                            src={`${backendUrl}${user?.image}`}
                        />
                    </MenuButton>
                    <MenuList maxW={"10px"}>
                        <MenuItem
                            onClick={() => {
                                navigate("/admin");
                            }}
                        >
                            Referalls
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                navigate("/admin/ambassadors");
                            }}
                        >
                            Ambassadors
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                navigate("/admin/job-seekers");
                            }}
                        >
                            Job Seekers
                        </MenuItem>
                        <MenuItem onClick={handleLogout}>Logout</MenuItem>
                    </MenuList>
                </Menu>
            </Flex>
        </nav>
    );
};

export default AdminNavbar;
