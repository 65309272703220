// src/components/AmbassadorsList.js
import React, { useState, useEffect } from "react";
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Flex,
    Avatar,
    Text,
    Button,
} from "@chakra-ui/react";
import { useAdmin } from "./../context/AdminContext";
import { useAuth } from "../context/AuthContext";
import axios from "axios";
import toast from "react-hot-toast";
import { MdDeleteOutline } from "react-icons/md";
import AdminNavbar from "./AdminNavbar";
import { useEnv } from "../context/EnvContext";

const AmbassadorsList = ({ show }) => {
    const { backendUrl } = useEnv();
    const { userData } = useAuth();
    const {
        fetchJobSeekers,
        jobSeekers,
        ambassadors,
        fetchNonDeletedUsers,
        handleRoleChange,
        deleteUser,
    } = useAdmin();
    const [candidates, setCandidates] = useState([]);

    useEffect(() => {
        if (
            Object.keys(userData).length === 0 &&
            userData.constructor === Object
        ) {
            return;
        }
        if (show === "Job Seekers") {
            fetchJobSeekers();
        } else {
            fetchNonDeletedUsers();
        }
    }, [userData, show]);

    useEffect(() => {
        if (show === "Job Seekers") {
            setCandidates(jobSeekers);
        } else {
            setCandidates(ambassadors);
        }
    }, [jobSeekers, ambassadors, show]);

    return (
        <>
            <AdminNavbar jobSeekers={jobSeekers} ambassadors={ambassadors} />
            <div className="mt-4">
                <h2 className="text-lg font-bold mb-2 mx-2">{show} List</h2>
                <TableContainer>
                    <Table size="sm">
                        <Thead>
                            <Tr>
                                <Th>sl.</Th>
                                <Th>Ambassador</Th>
                                <Th>College</Th>
                                <Th>Branch</Th>
                                <Th>Sem, PY</Th>
                                <Th>Contact</Th>
                                {show !== "Job Seekers" && <Th>Refs</Th>}
                                {show === "Job Seekers" && <Th>Action</Th>}
                            </Tr>
                        </Thead>
                        <Tbody>
                            {candidates.map((candidate, index) => (
                                <Tr key={index}>
                                    <Td>{index + 1}</Td>
                                    <Td>
                                        {" "}
                                        <Flex gap={2}>
                                            <Avatar
                                                size="sm"
                                                name={candidate?.name}
                                                src={`${backendUrl}${candidate?.image}`}
                                            />
                                            <Flex
                                                direction={"column"}
                                                justify={"center"}
                                            >
                                                <Text
                                                    fontSize={"sm"}
                                                    fontWeight={"bold"}
                                                >
                                                    {candidate.name}
                                                </Text>
                                                <Text>{candidate.email}</Text>
                                            </Flex>
                                        </Flex>
                                    </Td>
                                    <Td>{candidate.collegeName}</Td>
                                    <Td>{candidate.branch}</Td>
                                    <Td>
                                        {candidate.semester},{" "}
                                        {candidate.passoutYear}
                                    </Td>
                                    <Td>{candidate.contactNumber}</Td>
                                    {show !== "Job Seekers" && (
                                        <Td>{candidate.referralCount}</Td>
                                    )}
                                    {show !== "Job Seekers" && (
                                        <Td>
                                            <Button
                                                colorScheme="red"
                                                onClick={() =>
                                                    deleteUser(candidate._id)
                                                }
                                            >
                                                <MdDeleteOutline />
                                            </Button>
                                        </Td>
                                    )}
                                    {show === "Job Seekers" && (
                                        <Td>
                                            <Button
                                                colorScheme="blue"
                                                onClick={() =>
                                                    handleRoleChange(
                                                        candidate._id
                                                    )
                                                }
                                            >
                                                Make Ambassador
                                            </Button>
                                        </Td>
                                    )}
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </TableContainer>
            </div>
        </>
    );
};

export default AmbassadorsList;
