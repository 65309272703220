// src/components/AmbassadorDashboard.js
import React, { useEffect } from "react";
import ReferralForm from "./ReferralForm";
import AmbassadorNavbar from "./AmbassadorNavbar";
import { useLocation } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { useAmbassador } from "../context/AmbassadorContext";

const AmbassadorDashboard = () => {
    const location = useLocation();

    return (
        <div>
            <AmbassadorNavbar />
            <div className="p-4">
                {location?.state?.data?.operation == "update" ? (
                    <ReferralForm
                        candidate={location?.state?.data?.candidate}
                        operation={"Update Candidate"}
                    />
                ) : (
                    <ReferralForm operation={"Add Candidate"} />
                )}
            </div>
        </div>
    );
};

export default AmbassadorDashboard;
