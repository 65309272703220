// src/App.js
import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import LoginForm from "./components/LoginForm";
import AmbassadorDashboard from "./components/AmbassadorDashboard";
import AdminDashboard from "./components/AdminDashboard";
import toast, { Toaster } from "react-hot-toast";
import ReferralList from "./components/ReferralList";
import AmbassadorNavbar from "./components/AmbassadorNavbar";
import { useAuth } from "./context/AuthContext";
import CryptoJS from "crypto-js";
import HomeNavbar from "./components/HomeNavbar";
import AddAmbassadorForm from "./components/AddAmbassadorForm";
import AdminNavbar from "./components/AdminNavbar";
import AmbassadorsList from "./components/AmbassadorsList";
import { useAmbassador } from "./context/AmbassadorContext";
import { useAdmin } from "./context/AdminContext";

function App() {
    const key = "mona";
    const { userData, setUserData, setUser } = useAuth();
    const { fetchAllCandidates } = useAmbassador();
    const { fetchNonDeletedUsers, fetchJobSeekers, fetchDeletedUsers } =
        useAdmin();

    useEffect(() => {
        const storedUserData = localStorage.getItem("userData");
        if (storedUserData) {
            try {
                // Decrypt user data
                const bytes = CryptoJS.AES.decrypt(storedUserData, key);
                const decryptedData = JSON.parse(
                    bytes.toString(CryptoJS.enc.Utf8)
                );
                setUserData(decryptedData);
                setUser(decryptedData.user);
            } catch (e) {
                console.error("Decryption error: ", e);
                toast.error("Failed to load user data!", {
                    position: "top-right",
                });
            }
        }
    }, []);

    useEffect(() => {
        if (
            Object.keys(userData).length === 0 &&
            userData.constructor === Object
        ) {
            return;
        }

        // Fetch all datas
        if (userData.user.role == "Admin") {
            fetchNonDeletedUsers();
            fetchJobSeekers();
            fetchDeletedUsers();
        }

        if (
            userData.user.role == "Admin" ||
            userData.user.role == "Ambassador"
        ) {
            fetchAllCandidates();
        }
    }, [userData]);

    return (
        <>
            <Routes>
                <Route path="/" Component={LoginForm} />
                <Route path="/ambassador" Component={AmbassadorDashboard} />
                <Route
                    path="/ambassador/referrals"
                    element={
                        <>
                            <AmbassadorNavbar />
                            <ReferralList />
                        </>
                    }
                />
                <Route
                    path="/ambassador/update-profile"
                    element={
                        <>
                            <AmbassadorNavbar />
                            <AddAmbassadorForm
                                operation={"Update Ambassador"}
                            />
                        </>
                    }
                />

                <Route path="/admin" Component={AdminDashboard} />
                <Route
                    path="/apply-job"
                    element={
                        <>
                            <HomeNavbar task={"Login"} />
                            <AddAmbassadorForm operation={"Add Ambassador"} />
                        </>
                    }
                />
                <Route
                    path="/admin/job-seekers"
                    element={<AmbassadorsList show={"Job Seekers"} />}
                />
                <Route
                    path="/admin/ambassadors"
                    element={<AmbassadorsList show={"Ambassadors"} />}
                />
                <Route
                    path="/admin/referrals"
                    element={
                        <>
                            <AdminNavbar />
                            <ReferralList />
                        </>
                    }
                />
            </Routes>
            <Toaster containerStyle={{ marginTop: "5rem" }} />
        </>
    );
}

export default App;
