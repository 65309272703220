import { createContext, useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useEnv } from "./EnvContext";
import axios from "axios";
import { useAuth } from "./AuthContext";

const AmbassadorContext = createContext();

export const AmbassadorContextProvider = ({ children }) => {
    const { userData } = useAuth();
    const { backendUrl } = useEnv();
    const [candidates, setCandidates] = useState([]);

    const fetchAllCandidates = async () => {
        console.log("userData %%%", userData);
        const token = userData?.token;
        console.log(token);
        try {
            const response = await axios.get(
                `${backendUrl}/candidate/getAllCandidates`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            console.log("All Candidates Fetched successfully:", response.data);
            setCandidates(response.data);
        } catch (err) {
            console.log(err.response.data);
            toast.error(err.response.data.message || "Something went wrong", {
                position: "top-right",
            });
        }
    };

    const addCandidate = async (candidateData) => {
        console.log("userData %%%", userData);
        const token = userData?.token;
        let loadingToast;
        try {
            loadingToast = toast.loading("Adding Candidate", {
                position: "top-right",
            });
            const response = await axios.post(
                `${backendUrl}/candidate/addCandidate`,
                candidateData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            console.log("Candidate Added successfully:", response.data);
            toast.success("Candidate Added Successfully", {
                position: "top-right",
            });
            fetchAllCandidates();
        } catch (err) {
            console.log(err.response.data);
            toast.error(err.response.data.message || "Something went wrong", {
                position: "top-right",
            });
        } finally {
            if (loadingToast) toast.dismiss(loadingToast);
        }
    };

    const updateCandidate = async (candidateData, candidateId) => {
        const token = userData?.token;
        let loadingToast;
        try {
            loadingToast = toast.loading("Updating Candidate", {
                position: "top-right",
            });
            const response = await axios.put(
                `${backendUrl}/candidate/updateCandidate?candidateId=${candidateId}`,
                candidateData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            console.log("Candidate Updated successfully:", response.data);
            fetchAllCandidates(); // Refresh the candidates list
        } catch (err) {
            console.log(err.response.data);
            toast.error(err.response.data.message || "Something went wrong", {
                position: "top-right",
            });
        } finally {
            if (loadingToast) toast.dismiss(loadingToast);
        }
    };

    const deleteCandidate = async (candidateId) => {
        const token = userData?.token;
        let loadingToast;
        try {
            loadingToast = toast.loading("Deleting Candidate", {
                position: "top-right",
            });
            const response = await axios.delete(
                `${backendUrl}/candidate/deleteCandidate?candidateId=${candidateId}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            console.log("Candidate Deleted successfully:", response.data);
            fetchAllCandidates(); // Refresh the candidates list
        } catch (err) {
            console.log(err.response.data);
            toast.error(err.response.data.message || "Something went wrong", {
                position: "top-right",
            });
        } finally {
            if (loadingToast) toast.dismiss(loadingToast);
        }
    };

    const updateValidity = async (candidateId, validityStatus) => {
        try {
            const response = await axios.put(
                `${backendUrl}/candidate/validateCandidate?candidateId=${candidateId}`,
                { validityStatus: validityStatus },
                {
                    headers: {
                        Authorization: `Bearer ${userData.token}`,
                    },
                }
            );
            console.log("response", response.data);
            toast.success("Candidate Validity Status updated Successfully");

            // Update the candidates state
            setCandidates((prevCandidates) =>
                prevCandidates.map((candidate) =>
                    candidate._id === candidateId
                        ? { ...candidate, validityStatus: validityStatus }
                        : candidate
                )
            );
        } catch (error) {
            toast.error(
                error.response.data.message || "Failed to update user role"
            );
        }
    };

    useEffect(() => {
        if (
            Object.keys(userData).length === 0 &&
            userData.constructor === Object
        ) {
            return;
        }
        fetchAllCandidates();
    }, [userData]);

    return (
        <AmbassadorContext.Provider
            value={{
                candidates,
                fetchAllCandidates,
                addCandidate,
                updateCandidate,
                deleteCandidate,
                updateValidity,
            }}
        >
            {children}
        </AmbassadorContext.Provider>
    );
};

export default AmbassadorContext;

export const useAmbassador = () => useContext(AmbassadorContext);
