import {
    Button,
    Image,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
} from "@chakra-ui/react";
import React, { useRef } from "react";
import { useEnv } from "../context/EnvContext";

export const DisplayProfilePicModal = ({ children, selectedUser }) => {
    const { backendUrl } = useEnv();
    const { isOpen, onOpen, onClose } = useDisclosure();
    return (
        <div>
            <span onClick={onOpen}>{children}</span>
            <Modal isOpen={isOpen} onClose={onClose} size={"lg"} isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{selectedUser.name}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Image
                            src={`${backendUrl}${selectedUser.image}`}
                            alt={selectedUser.name}
                            w={"100%"}
                        />
                    </ModalBody>
                </ModalContent>
            </Modal>
        </div>
    );
};
