import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import { EnvContextProvider } from "./context/EnvContext";
import { AuthContextProvider } from "./context/AuthContext";
import { AdminContextProvider } from "./context/AdminContext";
import { AmbassadorContextProvider } from "./context/AmbassadorContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <BrowserRouter>
        <ChakraProvider>
            <EnvContextProvider>
                <AuthContextProvider>
                    <AmbassadorContextProvider>
                        <AdminContextProvider>
                            <App />
                        </AdminContextProvider>
                    </AmbassadorContextProvider>
                </AuthContextProvider>
            </EnvContextProvider>
        </ChakraProvider>
    </BrowserRouter>
);
