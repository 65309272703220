import { useState } from "react";
import {
    Badge,
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
} from "@chakra-ui/react";
import { useAmbassador } from "../context/AmbassadorContext";

export const ValidityUpdate = ({ selectedCandidate, children }) => {
    const { updateValidity } = useAmbassador();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [validityStatus, setValidityStatus] = useState(
        selectedCandidate.validityStatus
    );

    const handleChange = (event) => {
        setValidityStatus(event.target.value);
    };

    return (
        <>
            {children ? <span onClick={onOpen}>{children}</span> : <></>}

            <Modal size={"lg"} isOpen={isOpen} onClose={onClose} isCentered>
                <ModalOverlay />
                <ModalContent h={"auto"}>
                    <ModalHeader>
                        Current Validity Status{" -  "}
                        {selectedCandidate.validityStatus == "Valid" && (
                            <Badge colorScheme="green">Valid</Badge>
                        )}
                        {selectedCandidate.validityStatus == "Invalid" && (
                            <Badge colorScheme="red">Invalid</Badge>
                        )}
                        {selectedCandidate.validityStatus == "Pending" && (
                            <Badge colorScheme="orange">Pending</Badge>
                        )}
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <div className="mb-4">
                            <label className="block">
                                Update Validity Status
                            </label>
                            <select
                                name="validityStatus"
                                value={validityStatus}
                                onChange={handleChange}
                                className="border p-2 w-full"
                            >
                                <option value="Valid">Valid</option>
                                <option value="Invalid">Invalid</option>
                                <option value="Pending">Pending</option>
                            </select>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button
                            colorScheme="blue"
                            mr={3}
                            onClick={() => {
                                updateValidity(
                                    selectedCandidate._id,
                                    validityStatus
                                );
                                onClose();
                            }}
                        >
                            Update
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};
