import { createContext, useContext, useEffect, useState } from "react";
import { useAuth } from "./AuthContext";
import toast from "react-hot-toast";
import { useEnv } from "./EnvContext";
import axios from "axios";
import CryptoJS from "crypto-js";

const AdminContext = createContext();

export const AdminContextProvider = ({ children }) => {
    const key = "mona";
    const { userData, setUser, setUserData } = useAuth();
    const { backendUrl } = useEnv();
    const [ambassadors, setAmbassadors] = useState([]);
    const [jobSeekers, setJobSeekers] = useState([]);
    const [deletedUsers, setDeletedUsers] = useState([]);

    // Function to add a new user
    const addUser = async (formData) => {
        try {
            const response = await axios.post(
                `${backendUrl}/user/add_user`,
                formData
            );
            console.log("response", response.data);
            toast.success("User added successfully");
        } catch (error) {
            toast.error(error.response.data.message || "Failed to add user");
        }
    };

    // Function to update an existing user
    const updateUser = async (formData) => {
        try {
            const response = await axios.put(
                `${backendUrl}/user/update_user`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${userData.token}`,
                    },
                }
            );
            console.log("response", response.data);
            toast.success("User updated successfully");
            setUser(response.data.user);
            let updatedUserData = {
                token: userData.token,
                user: response.data.user,
            };
            setUserData(updatedUserData);

            const encryptedData = CryptoJS.AES.encrypt(
                JSON.stringify(updatedUserData),
                key
            ).toString();

            // Store encrypted data in localStorage
            localStorage.setItem("userData", encryptedData);
        } catch (error) {
            toast.error(error.response.data.message || "Failed to update user");
        }
    };

    // Function to delete a user
    const deleteUser = async (userId) => {
        try {
            const response = await axios.delete(
                `${backendUrl}/user?userId=${userId}`,
                {
                    headers: {
                        Authorization: `Bearer ${userData.token}`,
                    },
                }
            );
            console.log("response", response.data);
            toast.success("User deleted successfully");
            fetchNonDeletedUsers();
        } catch (error) {
            toast.error(error.response.data.message || "Failed to delete user");
        }
    };

    // Function to revive a user
    const reviveUser = async (userId) => {
        try {
            const response = await axios.put(
                `${backendUrl}/user/revive`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${userData.token}`,
                    },
                    params: { userId },
                }
            );
            toast.success("User revived successfully");
            fetchDeletedUsers();
        } catch (error) {
            toast.error(error.response.data.message || "Failed to revive user");
        }
    };

    // Function to fetch all non-deleted users
    const fetchNonDeletedUsers = async () => {
        try {
            const response = await axios.get(
                `${backendUrl}/user/getNonDeletedUsers`,
                {
                    headers: {
                        Authorization: `Bearer ${userData.token}`,
                    },
                }
            );
            setAmbassadors(response.data);
        } catch (error) {
            toast.error(error.response.data.message || "Failed to fetch users");
        }
    };

    // Function to fetch all deleted users
    const fetchDeletedUsers = async () => {
        try {
            const response = await axios.get(
                `${backendUrl}/user/getDeletedUsers`,
                {
                    headers: {
                        Authorization: `Bearer ${userData.token}`,
                    },
                }
            );
            setDeletedUsers(response.data);
        } catch (error) {
            toast.error(
                error.response.data.message || "Failed to fetch deleted users"
            );
        }
    };

    // Function to fetch Job Seekers
    const fetchJobSeekers = async () => {
        try {
            const response = await axios.get(`${backendUrl}/user/getJSUsers`, {
                headers: {
                    Authorization: `Bearer ${userData.token}`,
                },
            });
            setJobSeekers(response.data);
        } catch (error) {
            toast.error(error.response.data.message || "Failed to fetch users");
        }
    };

    const handleRoleChange = async (userId) => {
        try {
            const response = await axios.put(
                `${backendUrl}/user/add_ambassador?userId=${userId}`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${userData.token}`,
                    },
                    params: { userId },
                }
            );
            console.log("response", response.data);
            toast.success("User role updated to Ambassador successfully");
            fetchJobSeekers(); // Refresh the job seekers list after updating the role
        } catch (error) {
            toast.error(
                error.response.data.message || "Failed to update user role"
            );
        }
    };

    

    useEffect(() => {
        if (
            Object.keys(userData).length === 0 &&
            userData.constructor === Object
        ) {
            return;
        }
        if (userData.role == "Admin") {
            fetchNonDeletedUsers();
            fetchJobSeekers();
            fetchDeletedUsers();
        }
    }, [userData]);

    return (
        <AdminContext.Provider
            value={{
                ambassadors,
                deletedUsers,
                addUser,
                updateUser,
                deleteUser,
                reviveUser,
                fetchNonDeletedUsers,
                fetchDeletedUsers,
                fetchJobSeekers,
                jobSeekers,
                handleRoleChange,
            }}
        >
            {children}
        </AdminContext.Provider>
    );
};

export default AdminContext;

export const useAdmin = () => useContext(AdminContext);
