// src/components/ReferralList.js
import React, { useState, useEffect } from "react";
import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    useMediaQuery,
    Box,
    Flex,
    Avatar,
    Text,
    Badge,
} from "@chakra-ui/react";
import { formatDateTime } from "../utils/formatDT";
import { useAmbassador } from "./../context/AmbassadorContext";
import { useEnv } from "../context/EnvContext";
import { ProfileModal } from "./ProfileModal";
import { FaSort } from "react-icons/fa";
import { TiArrowSortedUp, TiArrowSortedDown } from "react-icons/ti";
import { ValidityUpdate } from "./ValidityUpdate";

const ReferralList = () => {
    const { backendUrl } = useEnv();
    const { candidates } = useAmbassador();
    const [sortedData, setSortedData] = useState(candidates);
    const [sortConfig, setSortConfig] = useState({
        key: null,
        direction: null,
    });

    // Sort the data
    useEffect(() => {
        let sortedArray = [...candidates];
        if (sortConfig.key) {
            sortedArray.sort((a, b) => {
                let aValue = a[sortConfig.key];
                let bValue = b[sortConfig.key];

                if (sortConfig.key === "referredBy.name") {
                    aValue = a.referredBy.name;
                    bValue = b.referredBy.name;
                }

                if (aValue < bValue) {
                    return sortConfig.direction === "ascending" ? -1 : 1;
                }
                if (aValue > bValue) {
                    return sortConfig.direction === "ascending" ? 1 : -1;
                }
                return 0;
            });
        }
        setSortedData(sortedArray);
    }, [sortConfig, candidates]);

    const requestSort = (key) => {
        let direction = "ascending";
        if (sortConfig.key === key && sortConfig.direction === "ascending") {
            direction = "descending";
        } else if (
            sortConfig.key === key &&
            sortConfig.direction === "descending"
        ) {
            setSortConfig({ key: null, direction: null });
            return;
        }
        setSortConfig({ key, direction });
    };

    const getSortIcon = (key) => {
        if (sortConfig.key === key) {
            if (sortConfig.direction === "ascending") {
                return <TiArrowSortedUp cursor={"pointer"} />;
            } else if (sortConfig.direction === "descending") {
                return <TiArrowSortedDown cursor={"pointer"} />;
            }
        }
        return <FaSort cursor={"pointer"} />;
    };

    return (
        <div className="mt-4">
            <h2 className="text-lg font-bold mb-2">All Referrals</h2>
            <div className="border p-4 rounded-md">
                {candidates.length === 0 ? (
                    <p>No Referalls found.</p>
                ) : (
                    <TableContainer>
                        <Table size="sm">
                            <Thead>
                                <Tr>
                                    <Th>Ref.</Th>
                                    <Th
                                        onClick={() =>
                                            requestSort("candidateId")
                                        }
                                    >
                                        <Flex
                                            alignItems={"center"}
                                            width={"100%"}
                                            justify={"space-between"}
                                        >
                                            Cand. Id{" "}
                                            {getSortIcon("candidateId")}
                                        </Flex>
                                    </Th>
                                    <Th
                                        onClick={() =>
                                            requestSort("referredBy.name")
                                        }
                                    >
                                        <Flex
                                            alignItems={"center"}
                                            width={"100%"}
                                            justify={"space-between"}
                                        >
                                            Referred By{" "}
                                            {getSortIcon("referredBy.name")}
                                        </Flex>
                                    </Th>
                                    <Th
                                        onClick={() =>
                                            requestSort("candidateName")
                                        }
                                    >
                                        <Flex
                                            alignItems={"center"}
                                            width={"100%"}
                                            justify={"space-between"}
                                        >
                                            Candidate{" "}
                                            {getSortIcon("candidateName")}
                                        </Flex>
                                    </Th>
                                    <Th
                                        onClick={() =>
                                            requestSort("collegeName")
                                        }
                                    >
                                        <Flex
                                            alignItems={"center"}
                                            width={"100%"}
                                            justify={"space-between"}
                                        >
                                            <Flex
                                                direction={"column"}
                                                justify={"center"}
                                            >
                                                <Text fontSize={"2xs"}>
                                                    College
                                                </Text>
                                                <Text fontSize={"2xs"}>
                                                    Contact
                                                </Text>
                                            </Flex>
                                            {getSortIcon("collegeName")}
                                        </Flex>
                                    </Th>
                                    <Th
                                        onClick={() =>
                                            requestSort("courseRegistered")
                                        }
                                    >
                                        <Flex
                                            alignItems={"center"}
                                            width={"100%"}
                                            justify={"space-between"}
                                        >
                                            Course{" "}
                                            {getSortIcon("courseRegistered")}
                                        </Flex>
                                    </Th>
                                    <Th
                                        onClick={() =>
                                            requestSort("validityStatus")
                                        }
                                    >
                                        <Flex
                                            alignItems={"center"}
                                            width={"100%"}
                                            justify={"space-between"}
                                        >
                                            Validity{" "}
                                            {getSortIcon("validityStatus")}
                                        </Flex>
                                    </Th>
                                    <Th
                                        onClick={() => requestSort("regProces")}
                                    >
                                        <Flex
                                            alignItems={"center"}
                                            width={"100%"}
                                            justify={"space-between"}
                                        >
                                            Process {getSortIcon("regProces")}
                                        </Flex>
                                    </Th>
                                    <Th
                                        onClick={() => requestSort("createdAt")}
                                    >
                                        <Flex
                                            alignItems={"center"}
                                            width={"100%"}
                                            justify={"space-between"}
                                        >
                                            Added On {getSortIcon("createdAt")}
                                        </Flex>
                                    </Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {sortedData.map((referral, index) => (
                                    <Tr key={index}>
                                        <Td> {candidates.length - index}</Td>
                                        <Td> {referral.candidateId}</Td>
                                        <Td>
                                            <ProfileModal
                                                selectedUser={
                                                    referral.referredBy
                                                }
                                            >
                                                <Flex gap={2}>
                                                    <Avatar
                                                        size="sm"
                                                        name={
                                                            referral?.referredBy
                                                                ?.name
                                                        }
                                                        src={`${backendUrl}${referral.referredBy?.image}`}
                                                    />
                                                    <Flex
                                                        direction={"column"}
                                                        justify={"center"}
                                                    >
                                                        <Text
                                                            fontSize={"sm"}
                                                            fontWeight={"bold"}
                                                        >
                                                            {
                                                                referral
                                                                    ?.referredBy
                                                                    ?.name
                                                            }
                                                        </Text>
                                                        <Text>
                                                            {
                                                                referral
                                                                    ?.referredBy
                                                                    ?.email
                                                            }
                                                        </Text>
                                                    </Flex>
                                                </Flex>
                                            </ProfileModal>
                                        </Td>
                                        <Td>
                                            <Flex
                                                direction={"column"}
                                                justify={"center"}
                                            >
                                                <Text
                                                    fontSize={"sm"}
                                                    fontWeight={"bold"}
                                                >
                                                    {referral.candidateName}
                                                </Text>
                                                <Text>
                                                    {referral.candidateEmail}
                                                </Text>
                                            </Flex>
                                        </Td>
                                        <Td>
                                            <Flex
                                                direction={"column"}
                                                justify={"center"}
                                            >
                                                <Text fontSize={"xs"}>
                                                    {referral.collegeName ==
                                                    "Other"
                                                        ? `${referral.otherCollegeName}`
                                                        : `${referral.collegeName}`}
                                                </Text>
                                                <Text
                                                    fontSize={"sm"}
                                                    fontWeight={"bold"}
                                                >
                                                    {referral.contactNumber}
                                                </Text>
                                            </Flex>
                                        </Td>
                                        <Td>{referral.courseRegistered}</Td>
                                        <Td>
                                            <ValidityUpdate
                                                selectedCandidate={referral}
                                            >
                                                {referral.validityStatus ==
                                                    "Valid" && (
                                                    <Badge
                                                        colorScheme="green"
                                                        cursor={"pointer"}
                                                    >
                                                        Valid
                                                    </Badge>
                                                )}
                                                {referral.validityStatus ==
                                                    "Invalid" && (
                                                    <Badge
                                                        colorScheme="red"
                                                        cursor={"pointer"}
                                                    >
                                                        Invalid
                                                    </Badge>
                                                )}
                                                {referral.validityStatus ==
                                                    "Pending" && (
                                                    <Badge
                                                        colorScheme="orange"
                                                        cursor={"pointer"}
                                                    >
                                                        Pending
                                                    </Badge>
                                                )}
                                            </ValidityUpdate>
                                        </Td>
                                        <Td>
                                            <Flex
                                                direction={"column"}
                                                justify={"center"}
                                            >
                                                <Text
                                                    fontSize={"sm"}
                                                    fontWeight={"bold"}
                                                >
                                                    {referral.regProces ==
                                                    "Manually"
                                                        ? "Manually"
                                                        : "Automatically"}
                                                </Text>
                                                <Text fontSize={"xs"}>
                                                    {referral.regProces ==
                                                        "Manually" &&
                                                        referral.transactionId}
                                                </Text>
                                            </Flex>
                                        </Td>
                                        <Td>
                                            <Flex
                                                direction={"column"}
                                                justify={"center"}
                                            >
                                                <Text
                                                    fontSize={"sm"}
                                                    fontWeight={"bold"}
                                                >
                                                    {
                                                        formatDateTime(
                                                            referral.createdAt
                                                        ).date
                                                    }
                                                </Text>
                                                <Text>
                                                    {
                                                        formatDateTime(
                                                            referral.createdAt
                                                        ).time
                                                    }
                                                </Text>
                                            </Flex>
                                        </Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </TableContainer>
                )}
            </div>
        </div>
    );
};

export default ReferralList;
