// src/components/AdminDashboard.js
import React from "react";
import AdminNavbar from "./AdminNavbar";
import AddAmbassadorForm from "./AddAmbassadorForm";
import CandidateTable from "./CandidateTable";
import AmbassadorsList from "./AmbassadorsList";

const AdminDashboard = () => {
    return (
        <div>
            <AdminNavbar />
            <div className="p-4">
                <CandidateTable />
            </div>
        </div>
    );
};

export default AdminDashboard;
